$yellowTransparent: rgba(252, 204, 36, 0.23);
$yellowLight: #fccc24;
$dark-red: linear-gradient(180deg, #ec286f 0%, #ff2373 100%);
$unactive-white: rgba(255, 255, 255, 0.5);
$border-white: rgba(255, 255, 255, 0.12);
$light-grey-color: #3d4151;
$base-color-story: #212538;
$zambezi-color: #5d5d5d;
$base-color: #15192a;

$mirage1: #1f253a;
$mirage2: #181d30;
$white: #fff;
$brightGray: #343849;
$brightGrayHover: #494f67;
$radicalRed: #fc2472;
$radicalRedHover: #fd5692;
$radicalRedLighter: rgba(252, 36, 114, 0.12);
$tuna: #323544;
$tunaHover: #484c61;
$ebonyClay: #212538;
$ebonyClayLight: #252a3c;
$mirage3: #141929;
$mirage4: #1f253a;
$mirage5: #121625;
$mirage6: #121624;
$pictonBlue: #3ac6e0;
$pictonBlueLighter: rgba(58, 198, 224, 0.12);
$pastelGreen: #75d180;
$pastelGreenLighter: rgba(117, 209, 128, 0.12);

$defaultColor: $white;

$frameBorderWidth: 6px;
$frameBorderWidthSm: 4px;
$frameBorderColor: #4d5160;

$xs: 576px;
$sm: 900px;
$md: 1024px;
$lg: 1366px;
$xl: 1600px;

$fullScreenContainerZindex: 99999;
$backpackZindex: 6;
$backpackItemZindex: 7;
$decisionZindex: 8;
$scaleFrame: 1.05;

:root {
  --primary-color: #{$radicalRed};
  --secondary-color: #{$ebonyClay};
  --close-icon-color: #{$white};
  --close-icon: '';

  --restart-icon-color: #{$white};
  --restart-icon: '';

  --backpack-icon-color: #{$white};
  --backpack-icon: '';

  --journal-icon-color: #{$white};
  --journal-icon: '';

  --next-icon-color: #{$white};
  --next-icon: '';

  --back-icon-color: #{$white};
  --back-icon: '';

  --background-color: #{$mirage2};
  --background-image: '';
  --active-frame-border-color: #{$white};
  --font-family: 'Roboto';

  --focus-direction: 'bottom left';
}
